






































































































































































import { Component, Vue } from "vue-property-decorator";
import { UserModule } from "@/store/modules/user";
import { groupApi } from "@/api/group";
import { departmentApi } from "@/api/department";
import { enterpriseApi, IGetEnterprisesParam } from "@/api/enterprise";
import { userApi } from "@/api/user";
import PERMISSION from "@/constants/permission";
import { onPreviewQrcode, downloadZip } from "@/utils/qrcode";

@Component
export default class Group extends Vue {
  private params = { page: 1, perPage: 15 };
  private groups = {};
  private loading = false;
  private visible = false;
  private createModifyLoad = false;
  private title = "新增仪器组";
  private openType = "create";
  private groupFields = [];
  private openLoading = false;
  batchSettingList = [];
  downloadLoading = false;
  private createModifyParams: any = {
    enterpriseId: 1,
    departmentId: null,
    name: "",
    code: "",
    extraFields: {},
  };
  private departments = {};
  private enterprises = {};
  private users = {};
  condition: any = {};
  private rules = {
    name: [{ required: true, message: "请输入名称", trigger: "blur" }],
    departmentId: [{ required: true, message: "请选择", trigger: "blur" }],
  };

  get batchSettingDisabled() {
    return !this.batchSettingList?.length;
  }
  get isSuperAdmin(): boolean {
    return UserModule.isSuperAdmin;
  }
  get PERMISSION() {
    return PERMISSION;
  }
  get extraConfig() {
    return [
      {
        key: "enterpriseId",
        label: "企业",
        type: "select",
        // @ts-ignore
        options: this.enterprises?.items?.map((child) => ({
          value: child.id,
          label: child.name,
        })),
        placeholder: "请选择企业",
        hidden: !this.isSuperAdmin,
      },
    ];
  }
  onRefresh(condition: any) {
    this.condition = condition;
    this.getGroups();
  }
  handleSelectionChange(row: any) {
    this.batchSettingList = row;
  }
  async handleCommand(command: string) {
    if (command === "batchDownloadQrcode") {
      this.downloadLoading = true;
      try {
        await downloadZip(
          this.batchSettingList?.map((row: any) => ({
            content: `https://www.zjiims.com/qr/group?id=${row.id}`,
            title: "IIMS仪器分组",
            subTitle: row.name,
          })),
          "IIMS仪器组二维码批量导出"
        );
      } catch (error) {}
      this.downloadLoading = false;
      return;
    }
  }
  private async onOpen(row: any, type = "create") {
    this.openType = type;
    this.openLoading = true;
    try {
      if (row) {
        const data = await this.getGroupDetail(row.id);
        this.createModifyParams = {
          ...data,
        };
        this.title = "编辑仪器组";
      } else {
        this.createModifyParams = {
          extraFields: {},
        };
        this.title = "新增仪器组";
      }
    } catch (error) {}
    this.openLoading = false;
    this.visible = true;
  }
  onPreviewQrcode(row:any) {
    onPreviewQrcode(
      this,
      `https://www.zjiims.com/qr/group?id=${row.id}`,
      "IIMS仪器分组",
      row.name
    );
  }
  private async onDelete(row: any) {
    this.$confirm(`是否继续删除仪器组 ${row.name}?`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        groupApi.deleteGroup(row.id).then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.getGroups();
        });
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
  }
  private async getGroups() {
    this.loading = true;
    try {
      const res = await groupApi.getGroups({
        ...this.params,
        ...this.condition,
      });
      this.groups = res;
    } catch (error) {}
    this.loading = false;
  }
  // @ts-ignore
  private async getGroupDetail(id) {
    return await groupApi.getGroupDetail(id);
  }

  private getEnterprises(query: string) {
    enterpriseApi
      .getEnterprises({
        name: query,
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          // @ts-ignore
          : UserModule?.user?.enterprise?.id,
      } as IGetEnterprisesParam)
      .then((data) => {
        this.enterprises = data;
      })
      .catch((e) => {
        this.$message.warning(`获取企业列表失败, ${e}`);
      });
  }

  private doAction() {
    // @ts-ignore
    this.$refs.createModifyRef.validate((valid: boolean) => {
      if (!valid) {
        this.$message.warning("请检查表单");
        return;
      }
      this.createModifyLoad = true;
      let func = groupApi.createGroup;
      // eslint-disable-next-line no-prototype-builtins
      if (this.createModifyParams.hasOwnProperty("id")) {
        // @ts-ignore
        func = groupApi.modifyGroup;
      }
      func(this.createModifyParams)
        .then(() => {
          this.$message.success(this.title);
          this.visible = false;
          this.getGroups();
        })
        .catch((e) => {
          this.$message.warning(`${this.title}, ${e}`);
        })
        .finally(() => {
          this.createModifyLoad = false;
        });
    });
  }

  private created() {
    this.getEnterprises("");
    // this.getGroups();
  }
  handleSizeChange(size: number) {
    this.params.perPage = size;
    this.getGroups();
  }

  handlePageChange(page: number) {
    this.params.page = page;
    this.getGroups();
  }
}
