import http, { encodeParams } from '@/utils/request'
import { IEnterprise, IEnterpriseDetail } from '@/api/enterprise'

export interface RolePerm {
  enterprise: Array<number>
  department: Array<number>
  instrumentGroup: Array<number>
  instrument: Array<number>
  perm: Array<number>
}

export interface IRoleDetail {
  gmtCreate: string
  gmtModify: string
  id: number
  enterpriseId: number
  name: string
  description: string
  visibleColumns: Array<string>
  perms: RolePerm
  enterprise: IEnterpriseDetail
  resources: Array<string>
}

export interface IRoles {
  total: number
  page: number
  perPage: number
  items: Array<IRoleDetail>
}

export interface IRoleParams {
  name: string
  enterpriseId: number
  page: number
  perPage: number
  baseOnly: number
}

export interface ICreateRoleParams {
  name: string
  description: string
  enterpriseId: number
  visibleColumns: Array<string>
  perms: RolePerm
}

export interface IModifyRoleParams {
  id: number
  name: string
  description: string
  enterpriseId: number
  visibleColumns: Array<string>
  perms: RolePerm
}

export interface RoleAddUserParams {
  userIds: Array<number>
  roleIds: Array<number>
  isSingleUser: boolean

}

export interface RoleAddPermParams {
  perms: Array<any>
  roleId: any
}

export const roleApi = {
  login: function() {
    return {}
  },
  logout: function() {
    return {}
  },
  getRoles: function(params: IRoleParams): Promise<IRoles> {
    return http.get('/role' + encodeParams(params))
  },
  createRole: function(param: ICreateRoleParams): Promise<{ id: number }> {
    return http.post('/role', param)
  },
  modifyRole: function(param: IModifyRoleParams): Promise<{ id: number }> {
    return http.put(`/role/${param.id}`, param)
  },
  deleteRole: function(departmentId: number): Promise<{ id: number }> {
    return http.delete(`/role/${departmentId}`)
  },
  roleAddUser: function(param: RoleAddUserParams): Promise<{ id: number }> {
    return http.post('/role/users', param)
  },
  roleAddPerm: function(param: RoleAddPermParams) {
    return http.post('/role/perms', param)
  }
}
