


































































































































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import {
  IRoleDetail,
  IRoleParams,
  ICreateRoleParams,
  IModifyRoleParams,
  roleApi,
  IRoles,
  RoleAddPermParams,
} from "@/api/role";
import {permApi, IPermDetail, IPerms} from "@/api/perm";
import {UserModule} from "@/store/modules/user";
import {
  enterpriseApi,
  IEnterprises,
  IGetEnterprisesParam,
} from "@/api/enterprise";
import PERMISSION from "@/constants/permission";

@Component
export default class Role extends Vue {
  load = false;
  params = {page: 1, perPage: 15} as IRoleParams;
  roles = {} as IRoles;
  enterprises = {} as IEnterprises;
  visible = false;
  permVisiable = false;
  title = "";
  createModifyParams = {} as ICreateRoleParams | IModifyRoleParams;
  createModifyLoad = false;
  getEnterprisesLoading = false;
  defaultCheckKeys: Array<string> = [];
  fields: any = [];
  condition: any = {};
  props = {
    label: "label",
    children: "children",
  };

  temRoleId: null | number = null;

  count = 1;
  perms = [] as any;
  excludeSystem = [] as any[];
  // @ts-ignore
  instruments = [] as {id: string, label: string}

  rules = {
    name: [
      {required: true, message: "请输入用户名称", trigger: "blur"},
      {min: 2, max: 128, message: "长度在 2 到 128 个字符", trigger: "blur"},
    ],
    status: [{required: true, message: "请输入地址", trigger: "blur"}],
    telephone: [
      {required: true, message: "请输入电话", trigger: "blur"},
      {length: 11, message: "请输入正确的电话号码", trigger: "blur"},
    ],
    enterpriseId: [{required: true, message: "请分配企业", trigger: "blur"}],
    departmentId: [{required: true, message: "请分配科室", trigger: "blur"}],
  };

  mounted() {
    // this.getRoles();
    this.getPerms();
    if (this.isSuperAdmin) {
      this.getEnterprises("");
    }
    if (!this.isSuperAdmin) {
      this.getFields();
    }
  }

  get extraConfig() {
    return [
      {
        key: "enterpriseId",
        label: "企业",
        type: "select",
        options: this.enterprises?.items?.map((child) => ({
          value: child.id,
          label: child.name,
        })),
        placeholder: "请选择企业",
        hidden: !this.isSuperAdmin,
      },
    ];
  }

  onRefresh(condition: any) {
    this.condition = condition;
    this.getRoles();
  }

  onCheckFields(node: any, tree: any) {
    const {checkedNodes, checkedKeys} = tree;
    this.createModifyParams.visibleColumns = checkedKeys;
  }

  async getFields() {
    const res: any = await enterpriseApi.getFields({
      // @ts-ignore
      enterpriseId: UserModule?.user?.enterprise?.id,
    });
    this.fields = [
      {
        id: "all",
        label: "全部",
        children: res
          ?.reduce((s: any[], c: any) => {
            if (c instanceof Array) {
              return [...s, ...c];
            }
            return [...s, c];
          }, [])
          .map(({key, value}: any) => ({id: key, label: value})),
      },
    ];
  }

  getEnterprises(query: string) {
    this.getEnterprisesLoading = true;
    enterpriseApi
      .getEnterprises({
        name: query,
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          // @ts-ignore
          : UserModule?.user?.enterprise?.id,
      } as IGetEnterprisesParam)
      .then((data) => {
        this.enterprises = data;
      })
      .catch((e) => {
        this.$message.warning(`获取企业列表失败, ${e}`);
      })
      .finally(() => (this.getEnterprisesLoading = false));
  }

  get isSuperAdmin(): boolean {
    return UserModule.isSuperAdmin;
  }

  get PERMISSION() {
    return PERMISSION;
  }

  handleClose(done: any) {
    this.defaultCheckKeys = [];
    done();
  }

  getPerms() {
    this.load = true;
    permApi
      .getPerms()
      .then((data) => {
        let needPop = []
        // @ts-ignore
        this.perms = [data.items.find((item) => item.id === 5)]
        this.excludeSystem = []
        for (const item of data.items) {
          if (item.id === 5) {
            continue
          }
          // @ts-ignore
          item.value = []
          // @ts-ignore
          item.defaultChildren = item.children
          this.excludeSystem.push(item)
        }
      })
      .catch((e) => {
        this.$message.warning(`获取权限失败, ${e}`);
      })
      .finally(() => {
        this.load = false;
      });
  }

  filterChildren(val: string) {
    if (val && val.length === 0) {
      return
    }
    for (const item of this.excludeSystem) {
      // @ts-ignore
      item.children = item.defaultChildren.filter((i) => i.label.includes(val))
    }
  }

  onBlur() {
    for (const item of this.excludeSystem) {
      item.children = item.defaultChildren
    }
  }

  getRoles() {
    this.load = true;
    if (!this.isSuperAdmin) {
      // @ts-ignore
      this.params.enterpriseId = UserModule?.user?.enterprise?.id;
    }
    roleApi
      .getRoles({
        ...this.params,
        ...this.condition,
      })
      .then((data) => {
        this.roles = data;
      })
      .catch((e) => {
        this.$message.warning(`获取角色失败, ${e}`);
      })
      .finally(() => {
        this.load = false;
      });
  }

  handleSizeChange(size: number) {
    this.params.perPage = size;
    this.getRoles();
  }

  handlePageChange(page: number) {
    this.params.page = page;
    this.getRoles();
  }

  createRole() {
    this.title = "添加角色";
    this.createModifyParams = {} as ICreateRoleParams;
    this.visible = true;
    this.$forceUpdate();
  }

  modifyRole(row: IRoleDetail) {
    this.title = `编辑${row.name}角色信息`;
    this.createModifyParams = {
      id: row.id,
      name: row.name,
      description: row.description,
      enterpriseId: row.enterpriseId,
      visibleColumns: row.visibleColumns,
    } as IModifyRoleParams;
    this.enterprises = this.enterprises?.items?.find(
      ({id}) => id === row.enterpriseId
    )
      ? this.enterprises
      : ({
        items: [
          {id: row.enterpriseId, name: row.enterprise.name},
          ...(this.enterprises?.items || []),
        ],
      } as IEnterprises);
    this.visible = true;
    this.$forceUpdate();
  }

  updatePerm(row: IRoleDetail) {
    this.title = `分配角色 ${row.name} 权限`;
    this.permVisiable = true;
    this.temRoleId = row.id;
    this.defaultCheckKeys = row.resources;
    for (const item of this.excludeSystem) {
      item.value = []
      for (const child of item.children) {
        if (this.defaultCheckKeys.indexOf(child.id) !== -1) {
          item.value.push(child.id)
        }
      }
    }
  }

  deleteRole(row: IRoleDetail) {
    this.$confirm(`是否继续删除角色 ${row.name}?`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        roleApi.deleteRole(row.id).then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.getRoles();
        });
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
  }

  doAction() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // return
    this.$refs.createModifyRef.validate((valid: boolean) => {
      if (!valid) {
        this.$message.warning("请检查表单");
        return;
      }
      this.createModifyLoad = true;
      let func = roleApi.createRole;
      // eslint-disable-next-line no-prototype-builtins
      if (this.createModifyParams.hasOwnProperty("id")) {
        // @ts-ignore
        func = roleApi.modifyRole;
      }
      const {visibleColumns} = this.createModifyParams;
      func({
        ...this.createModifyParams,
        visibleColumns: visibleColumns
          ?.filter((key) => key !== "all")
          .map((key) => String(key)),
      })
        .then(() => {
          this.$message.success(this.title);
          this.visible = false;
          this.getRoles();
        })
        .catch((e) => {
          this.$message.warning(`${this.title}, ${e}`);
        })
        .finally(() => {
          this.createModifyLoad = false;
          this.createModifyParams.visibleColumns = [];
        });
    });
  }

  doPermAction() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.createModifyLoad = true;
    // eslint-disable-next-line no-prototype-builtins
    // @ts-ignore
    const perms = this.$refs.tree.getCheckedNodes()
    for (const item of this.excludeSystem) {
      for (const value of item.value) {
        perms.push({id: value})
      }
    }
    roleApi
      .roleAddPerm({
        perms: perms,
        roleId: this.temRoleId as number,
      } as RoleAddPermParams)
      .then(() => {
        this.permVisiable = false;
        this.getRoles();
        this.$message.success("修改成功");
      })
      .catch((e) => {
        this.$message.warning(`${this.title}, ${e}`);
      })
      .finally(() => {
        this.createModifyLoad = false;
      });
  }
}
