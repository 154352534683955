import http, { encodeParams } from '@/utils/request'

export interface IGroupParams {
  name: string
  enterpriseId: number
}

export interface IModifyGroupParams {
  id: number
  name: string
  enterpriseId: number
  description: number
}

export interface ICreateGroupParams {
  name: string
  enterpriseId: number
  description: number
}

export const groupApi = {
  getGroups: function (params: IGroupParams) {
    return http.get('/device' + encodeParams(params))
  },
  getGroupDetail: function (id: number) {
    return http.get(`/device/${id}`)
  },
  createGroup: function (params: ICreateGroupParams) {
    return http.post('/device', params)
  },
  modifyGroup: function (params: IModifyGroupParams) {
    return http.put(`/device/${params.id}`, params)
  },
  deleteGroup: function (id: number) {
    return http.delete(`/device/${id}`)
  },
}
