import http, { encodeParams } from '@/utils/request'
import { IPageResult } from '@/api/base'
import { IUserDetail } from '@/api/user'

export interface IGetEnterprisesParam {
  name?: string
  telephone?: string
  telephoneOwner?: string
  status?: number
  page?: number
  perPage?: number
}

export interface IEnterprise {
  id: number
  name: string
  status: number
  telephoneOwner: string
  telephone: string
  gmtCreate: string
  gmtModify: string
}

export interface IEnterprises extends IPageResult {
  items: Array<IEnterprise>
}

export interface IModifyEnterpriseParam {
  name: string
  description: string
  address: string
  logo: string
  repairRoles: number[]
  creCode: string
}

export interface ICreateEnterpriseParam {
  name: string
  description: string
  telephoneOwner: string
  telephone: string
  address: string
  logo: string
  creCode: string
}

export interface IInsEntryCol {
  id?: number
  enterpriseId?: number
  name: string
  type: string
  required: boolean
  isSelect: boolean
  selectAttrs: { value: string, code?: string }[]
  description: string
}

export interface IEnterpriseDetail {
  id: number
  name: string
  status: number
  owner: IUserDetail
  description: string
  address: string
  logo: string
  insAttrs: Array<IInsEntryCol>
  repairRoles: number[]
  creCode: string
  gmtCreate: string
  gmtModify: string
}

export interface ISortParams {
  enterpriseId?: number
  columnsSort: any
}

export interface IGetFieldsParams {
  enterpriseId?: number
  type?: string
}

export const enterpriseApi = {
  getEnterprises: function (params: IGetEnterprisesParam): Promise<IEnterprises> {
    return http.get('/enterprise' + encodeParams(params))
  },
  createEnterprise: function (param: ICreateEnterpriseParam): Promise<{ id: number }> {
    return http.post('/enterprise', param)
  },
  modifyEnterprise: function (param: IModifyEnterpriseParam): Promise<{ id: number }> {
    return http.put(`/enterprise/${param.name}`, param)
  },
  getEnterpriseDetail: function (enterpriseName: string): Promise<IEnterpriseDetail> {
    return http.get(`/enterprise/${enterpriseName}`)
  },
  deleteEnterprise: function (enterpriseName: string): Promise<IEnterpriseDetail> {
    return http.delete(`/enterprise/${enterpriseName}`)
  },
  lockEnterprise: function (enterpriseName: string): Promise<IEnterpriseDetail> {
    return http.put(`/enterprise/${enterpriseName}/lock`)
  },
  unlockEnterprise: function (enterpriseName: string): Promise<IEnterpriseDetail> {
    return http.put(`/enterprise/${enterpriseName}/unlock`)
  },
  modifyEnterpriseAttr: function (enterpriseName: string, params: Array<IInsEntryCol>): Promise<IEnterpriseDetail> {
    return http.put(`/enterprise/${enterpriseName}/attr`, { attrs: params })
  },
  search: function (params: IGetEnterprisesParam): Promise<IEnterprises> {
    return http.get('/enterprise' + encodeParams(params))
  },
  getFields: function (params: IGetFieldsParams): Promise<IEnterprises> {
    return http.get('/enterprise/fields' + encodeParams(params))
  },
  getFieldsSort: function (params: { enterpriseId: number }): Promise<IEnterprises> {
    return http.get('/enterprise/fields/sort' + encodeParams(params))
  },
  fieldsSort: function (params: ISortParams): Promise<IEnterpriseDetail> {
    return http.put(`/enterprise/fields/sort`, params)
  },
}
