import http, { encodeParams } from '@/utils/request'

export interface IPermDetail {
  id: number
  name: string
  module: string
  path: string
}

export interface IPerms {
  total: number
  page: number
  perPage: number
  items: Array<IPermDetail>
}

export const permApi = {
  getPerms: function(): Promise<IPerms> {
    return http.get('/perm')
  }
}
